import * as React from "react"
import ButtonAppBar from './bar'
import AboutUsSection from './about_us'
import MissionSection from './mission'
import CustomTheme from "../theme/theme"
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useCallback, useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import ValuesSection from "./values"
import TeamSection from "./team"
import ContactSection from "./contact"
import FooterSection from "./footer"
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center'
  },
  pageStyles: {
    padding: 32,
    backgroundColor: '#FAFAFA'
  },
  barStyles: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: '#FAFAFADD'
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  }
}))

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

// markup
const IndexPage = () => {
  const classes = useStyles(CustomTheme);

  const [index, setIndex] = useState(0);

  const elRefs = React.useRef([]);

  const arrLength = 5;
  let previousIndex = 0;

  const handleCallback = useCallback((newIndex) => {

    if(newIndex != null && elRefs.current[newIndex] != null && elRefs.current[newIndex].current != null) {
    elRefs.current[newIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIndex(newIndex + 0)
    }
    //ReactDOM.findDOMNode(this.refs.theDiv).focus();
  }, []);


  const scrollToContacts = useCallback(() => {
    var newIndex = 4;

    if(newIndex != null && elRefs.current[newIndex] != null && elRefs.current[newIndex].current != null) {
    elRefs.current[newIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIndex(newIndex + 0)
    }
    //ReactDOM.findDOMNode(this.refs.theDiv).focus();
  }, []);

  if (elRefs.current.length !== arrLength) {
    // add or remove refs
    elRefs.current = Array(arrLength).fill().map((_, i) => elRefs.current[i] || React.createRef());
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  
    elRefs.current.forEach((element, localIndex) => {
      //debugger;
      // console.log(localIndex + '-1->' + element.current.offsetTop + (element.current.offsetHeight / 2));
      // console.log('2->' + element.current.offsetTop + (element.current.offsetHeight / 1));
      // console.log('3->' + position);
      if((element.current.offsetTop - 300) < position && 
        ((element.current.offsetTop - 300) + (element.current.offsetHeight / 1)) > position) {
          if(previousIndex != localIndex) {
            // //alert(localIndex)
            // console.log('localIn->' + localIndex);
            // console.log('local->' + previousIndex);
            setIndex(localIndex)
            previousIndex = localIndex;
          }
      }
    });
  };
  
useEffect(() => {
  window.addEventListener('scroll', handleScroll, { passive: true });

  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
}, []);

  return (
    <main className={classes.root}>
      <ThemeProvider theme={CustomTheme}>
      <HideOnScroll>
        <AppBar elevation={0} className={classes.barStyles}>
          <ButtonAppBar index={index} handleCallback={handleCallback}/>
        </AppBar>
      </HideOnScroll>
      <div ref={elRefs.current[0]} item xs={12} className={classes.pageStyles}>
        <AboutUsSection  render={index == 0} scrollToContacts={scrollToContacts} />
      </div>
      <div ref={elRefs.current[1]} item xs={12}>
        <MissionSection render={index == 1}/>
      </div>
      <div ref={elRefs.current[2]} item xs={12}>
        <ValuesSection render={index == 2} />
      </div>
      <div ref={elRefs.current[3]} item xs={12}>
        <TeamSection render={index == 3}/>
      </div>
      <div ref={elRefs.current[4]} item xs={12}>
        <ContactSection render={index == 4}/>
      </div>
      <FooterSection />
      </ThemeProvider>
    </main>
  )
}

export default IndexPage
