import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';

import TransparencyImage from "../images/transparency.png";
import CollaborativeWorkImage from "../images/collaborative_work.svg";
import InnovationImage from "../images/innovation.svg";
import TraningImage from "../images/training.png";
import WorkLifeBlendImage from "../images/work_life_blend.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //height: 650,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 24,
        fontWeight: 700,
      },
      buttonUnderline: {
        backgroundColor: theme.palette.primary.main,
        height: 14,
        width: 50,
        margin: 'auto',
        marginTop: theme.spacing(3)
      },
      buttonUnderlineValues: {
        backgroundColor: theme.palette.secondary.dark,
        height: 8,
        width: 30,
        margin: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
      },
      imageBoxVertical: {
        maxWidth: 120,
        borderRadius: 0,
        margin: 'auto',
        backgroundColor: 'transparent'
    },
    mediaVertical: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
      rootGrid: {
          margin: 'auto',
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: '0 10px',
      },
      //gridItem: {
          //marginBottom: theme.spacing(8),
          //paddingLeft: theme.spacing(2),
          //paddingRight: theme.spacing(2),
      //}
}))



export default function ValuesSection(props) {

    const classes = useStyles();

    //debugger
  //console.log('INDEX --> ' + props.render)

    return (
        <div className={classes.root}>
        <Typography className={classes.title}>VALUES</Typography>
        <Grow in={true}><div className={classes.buttonUnderline} /></Grow>
        {/*<div className={classes.introBox}>
        <Typography className={classes.intro}>
        
        </Typography>
    </div>*/}
        <div >
        <Grid container className={classes.rootGrid} justifyContent="center">
                <Grid item xs={6} sm={4} md={2}>
                    <Grow timeout={1000} in={props.render}>
                    <Card className={classes.imageBoxVertical} elevation={0}>
                        <CardMedia
                            className={classes.mediaVertical}
                            image={WorkLifeBlendImage}
                        />
                    </Card>

                    </Grow>
                    <Grow in={true}><div className={classes.buttonUnderlineValues} /></Grow>
                    <Typography>WORK LIFE BLEND</Typography>
                    {/*<Typography></Typography>
                    <Typography>“Work-life blend doesn’t mean that everything is happening at the same time, all the time. It’s about finding a way to fit together the important pieces.”</Typography>*/}
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Grow timeout={1500} in={props.render}>
                    <Card className={classes.imageBoxVertical} elevation={0}>
                        <CardMedia
                            className={classes.mediaVertical}
                            image={CollaborativeWorkImage}
                        />
                    </Card>
                    </Grow>
                    <Grow in={true}><div className={classes.buttonUnderlineValues} /></Grow>
                    <Typography>COLLABORATIVE WORK</Typography>
                    {/*<Typography>(Promote collaborative work and be human)</Typography>
                    <Typography>As real humans, failure is our surname, but giving up is the only sure way to fail. Failure is Good as long as it doesn’t become an habit. Here you will find people that don’t know everything but everyone knows something.</Typography>*/}
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Grow timeout={2000} in={props.render}>
                    <Card className={classes.imageBoxVertical} elevation={0}>
                        <CardMedia
                            className={classes.mediaVertical}
                            image={TransparencyImage}
                        />
                    </Card>
                    </Grow>
                    <Grow in={true}><div className={classes.buttonUnderlineValues} /></Grow>
                    <Typography>TRANSPARENCY</Typography>
                    {/*<Typography>(X-ray vision)</Typography>
                    <Typography>“We can see through a lot, but communication is always essential.”</Typography>*/}
                </Grid>
                <Grid item  xs={6} sm={4} md={2}>
                <Grow timeout={2500} in={props.render}>
                    <Card className={classes.imageBoxVertical} elevation={0}>
                        <CardMedia
                            className={classes.mediaVertical}
                            image={TraningImage}
                        />
                    </Card>
                    </Grow>
                    <Grow in={true}><div className={classes.buttonUnderlineValues} /></Grow>
                    <Typography>TRAINING</Typography>
                    {/*<Typography>(Become the C.I.C.D.)</Typography>*/}
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                <Grow timeout={3000} in={props.render}>
                    <Card className={classes.imageBoxVertical} elevation={0}>
                        <CardMedia
                            className={classes.mediaVertical}
                            image={InnovationImage}
                        />
                    </Card>
                    </Grow>
                    <Grow in={true}><div className={classes.buttonUnderlineValues} /></Grow>
                    <Typography>INNOVATION</Typography>
                    {/*<Typography>(Keep Growing)</Typography>
                    <Typography>We believe true growth is achieved out of the comfort zone</Typography>*/}
                </Grid>
            </Grid>
            
        </div>
        </div>
    )
}