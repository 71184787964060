import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import theme from "../theme/theme"
import PartnerImage from "../images/google_cloud_partner.png";
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
    }
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  intro: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(8),
    textAlign: 'justify'
  },
  introBox: {
    maxWidth: 580,
    margin: 'auto'
  },
  title: {
    color: theme.palette.secondary.dark,
    fontSize: 40,
    fontWeight: 700,
    paddingTop: theme.spacing(2),
  },
  buttonUnderline: {
    backgroundColor: theme.palette.secondary.dark,
    height: 14,
    width: 50,
    margin: 'auto',
    marginTop: theme.spacing(3)
  },
  buttonStyle: {
    color: theme.palette.secondary.main,
    fontWeight: 600
  },
  buttonBox: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  partners: {
    backgroundImage: `url(${PartnerImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    position: "absolute",
    top: 0,
    right: 0,
    marginTop: -30, // 16:9
    borderRadius: 30
  },
  imageBox: {
    maxWidth: 120,
    borderRadius: 0,
    marginTop: -30,
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
    borderRadius: 30
  },
}))


export default function AboutUsSection(props) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.subTitle}>WELCOME TO LITTHUB</Typography>
      <Typography className={classes.title}>TECHNOLOGY FOR HEALTHCARE</Typography>
      <Grow timeout={2000} in={props.render}><div className={classes.buttonUnderline} /></Grow>
      <div className={classes.introBox}>
        <Typography className={classes.intro}>
          LITTHUB is a Lisbon based company focused on technology for healthcare. We are passionate about
          technology and empowered by our willingness to have people and technology
          working together to create more sustainable healthcare services.
          Our team uses trendy technologies such as React, Flutter, Node.js, Go, kubernetes and more.
        </Typography>
      </div>
      <div className={classes.buttonBox}>
        <Button 
          variant="outlined" 
          borderColor={theme.palette.secondary.dark} 
          color="secondary"
          onClick={props.scrollToContacts}>
          <Typography className={classes.buttonStyle}>GET IN TOUCH</Typography>
        </Button>
      </div>
      <Card className={classes.imageBox} elevation={2}>
        <Link href="https://cloud.withgoogle.com/partners/detail/?id=litthub" target="_blank"><CardMedia
          className={classes.media}
          image={PartnerImage}
        /></Link>
      </Card>

    </div>
  )
//https://cloud.withgoogle.com/partners/detail/?id=litthub&hl=pt
}